import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { id: "user-view-table", fluid: "", tag: "section" } },
        [
          _c(
            VForm,
            {
              ref: "form",
              attrs: { "lazy-validation": _vm.lazy },
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.get_users_by_page()
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                VRow,
                {
                  staticClass: "mt-6",
                  attrs: { justify: "center", align: "center" },
                },
                [
                  _c(VTextField, {
                    staticClass: "mx-3",
                    style: _vm.isMobile() ? "max-width: 25%" : "max-width: 35%",
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      label: _vm.$t("name"),
                    },
                    model: {
                      value: _vm.name_filter,
                      callback: function ($$v) {
                        _vm.name_filter = $$v
                      },
                      expression: "name_filter",
                    },
                  }),
                  _c(VSelect, {
                    staticClass: "mx-3",
                    staticStyle: { "max-width": "30%" },
                    attrs: {
                      outlined: "",
                      "hide-details": "",
                      dense: "",
                      label: _vm.$t("sort_by"),
                      items: _vm.filter_order_options.filter(function (x) {
                        return (
                          x.partnerOnly == (_vm.userType == 0 ? false : true)
                        )
                      }),
                      "item-text": "text",
                      "item-value": "index",
                    },
                    model: {
                      value: _vm.filter_order_selected,
                      callback: function ($$v) {
                        _vm.filter_order_selected = $$v
                      },
                      expression: "filter_order_selected",
                    },
                  }),
                  _c(VCheckbox, {
                    staticClass: "mx-3 mt-0",
                    staticStyle: { "max-width": "20%" },
                    attrs: { outlined: "", "hide-details": "", dense: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticStyle: { color: "var(--dark)" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("only_users_with_permission"))
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      4254300826
                    ),
                    model: {
                      value: _vm.allowedOnly,
                      callback: function ($$v) {
                        _vm.allowedOnly = $$v
                      },
                      expression: "allowedOnly",
                    },
                  }),
                  _c(
                    VBtn,
                    {
                      staticClass: "mx-3 secondary",
                      attrs: { type: "submit" },
                    },
                    [_vm._v(_vm._s(_vm.$t("apply")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            { staticClass: "mb-6" },
            [
              _c(
                VTabs,
                {
                  attrs: { centered: "" },
                  on: { change: _vm.user_type_changed },
                  model: {
                    value: _vm.userType,
                    callback: function ($$v) {
                      _vm.userType = $$v
                    },
                    expression: "userType",
                  },
                },
                [
                  _c(VTab, [_vm._v(_vm._s(_vm.$tc("investor", 2)))]),
                  _c(VTab, [_vm._v(_vm._s(_vm.$tc("partner", 2)))]),
                ],
                1
              ),
            ],
            1
          ),
          _c(VSimpleTable, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("name"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("email"))),
                ]),
                _vm.userType > 0
                  ? _c("th", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(_vm.$tc("user", 2))),
                    ])
                  : _vm._e(),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("briefing_permission"))),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.users, function (user, index) {
                return _c("tr", { key: index }, [
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.show_name(user))),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(user.Email)),
                  ]),
                  _vm.userType > 0
                    ? _c("td", { staticClass: "text-center" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              user.InvitedUsers.length != 0
                                ? user.InvitedUsers.length
                                : "-"
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _c(VCheckbox, {
                        staticClass: "permisision-checkbox",
                        on: {
                          change: function ($event) {
                            return _vm.change_permission(user)
                          },
                        },
                        model: {
                          value: user.BriefingPermission,
                          callback: function ($$v) {
                            _vm.$set(user, "BriefingPermission", $$v)
                          },
                          expression: "user.BriefingPermission",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
          _c(VPagination, {
            attrs: { color: "secondary", length: _vm.total_pages },
            on: { input: _vm.changePage },
            model: {
              value: _vm.page,
              callback: function ($$v) {
                _vm.page = $$v
              },
              expression: "page",
            },
          }),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }